.iconbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    opacity: 0.9;
  }
}
