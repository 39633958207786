.container {
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: center;
  background-color: #6822b8;
  padding: 0 24px;
  border-radius: 8px;

  .row {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}
