@import "../../../styles/_variables";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: $spacing-extra-large;
  padding: $spacing-extra-large;
  background-color: $primaryColor;
  border-radius: $border-radius-large;

  .titleSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .textSection {
      display: flex;
      flex-direction: column;
      gap: 0;

      .title {
        font-size: 22px;
        font-weight: bold;
        color: $purple-1000;
        font-family: $heading-font-family;
      }

      .description {
        font-size: 12px;
        color: $purple-200;
      }
    }

    .iconSection {
      height: 32px;
      width: 32px;
      img {
        padding: 8px;
        border-radius: $border-radius-large;
        border: 1px solid $purple-200;
      }
    }
  }

  .tableSection {
    width: 100%;
    overflow-x: scroll;
    table {
      border-collapse: collapse;
      text-align: center;

      thead {
        background-color: $secondaryColor;

        tr {
          border-radius: $border-radius-small;

          td {
            padding: $spacing-large;
            font-size: 14px;
            font-weight: bold;
            color: $purple-1000;
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: $purple-200;
            cursor: pointer;
          }

          td {
            padding: $spacing-large;
            font-weight: semi-bold;
            color: $purple-400;
          }

          .strong {
            font-weight: bold;
          }

          button {
            display: flex;
            flex-direction: row;
            gap: $spacing-extra-small;
            align-items: center;
            justify-content: center;

            img {
              height: 12px;
            }

            p {
              font-size: 14px;
            }
            border: 1px solid $purple-400;
            height: 32px;
            width: 88px;

            &:hover {
              background-color: $purple-400;
              color: $secondaryColor;
            }
          }
        }
      }
    }
  }
}
