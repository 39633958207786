.container {
  width: calc(100% - 24px);
  padding: 12px;
  background-color: white;
  border-radius: 12px;
  min-height: 400px;

  .headerSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    input {
      padding: 12px;
      outline: none;
      border: 0.4px solid rgb(172, 157, 192);
      border-radius: 4px;
      color: rgb(49, 16, 87);
    }

    .buttonSection {
      display: flex;
      flex-direction: row;
      gap: 12px;

      select {
        background-color: rgb(234, 234, 234);
        border-radius: 4px;
        border: none;
        padding: 12px 16px;
      }
    }
  }

  .tableSection {
    padding: 12px;
    table {
      border-collapse: collapse;
      width: 100%;
      color: rgb(124, 110, 144);

      thead {
        background-color: rgb(238, 238, 238);
        tr {
          border-radius: 12px;
        }
        td {
          padding: 12px 18px;
          font-weight: 700;
        }
      }

      tbody {
        td {
          padding: 12px 18px;
        }

        img {
          filter: grayscale(100%);
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;

          &:hover {
            filter: grayscale(0);
            cursor: pointer;
            border: 1px solid green;
          }
        }
      }
    }
  }
}
