@import "../../styles/_variables";

.container {
  .section1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .section2 {
    .name{
        color:$purple-900;
        font-size: $spacing-extra-large;
        font-weight: 600;
    }
    .description{
      color:$purple-400;
      font-size:$spacing-small;
    }
  }
  
  .section3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item {
    margin-right: 20px; /* Adjust spacing between items as needed */
  }

  .customSelect {
    display: flex;
    align-items:center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 7px;
    background-color: #f9f9f9;

    .icon {
      width: 14px;
      height: auto; 
      cursor: pointer;
    }
  
  }


 
 

  input[type="checkbox"] {
    margin-right: 5px; 
    width:20px;
  }

  select {
    border: none;
    background: transparent;
    font-size: 12px;
    padding: 2px;
    margin: 0;
    cursor: pointer;
    width: 100%;
  }

  select option {
    background-color: #fff;
    color: #000;
  }
}
