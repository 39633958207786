.container {
  background-color: white;
  border-radius: 12px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 24px;
  height: 212px;

  .headerSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .bodySection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .rowMethod {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
