@import "../../styles/_variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .greetSection {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .title {
      font-size: 29px;
      font-weight: bold;
      font-family: $heading-font-family;
      color: $purple-1000;
    }
    .subtitle {
      color: $purple-400;
    }
  }

  .filterSection {
    display: flex;
    flex-direction: row;
    gap: $spacing-small;

    select {
      height: 40px;
      background-color: $primaryColor;
      border-radius: $border-radius-large;
      font-size: $base-font-size;
    }
  }
}
