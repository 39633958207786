@import "../../styles/_variables";

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  .pageNumber {
    margin: 0 5px;
    padding: 8px 12px;
    cursor: pointer;
    color: $purple-900;

    &:hover {
      background-color: $purple-200;
      border-radius: 4px;
    }

    &.active {
      background-color: $purple-900;
      color: $secondaryColor;
      border-radius: 4px;
    }
  }

  .dots {
    margin: 0 5px;
    padding: 8px 12px;
    color: $purple-900;
  }
}
