//Colors
$primaryColor: #ffffff;
$secondaryColor: #f1f0f8;
$accentColor: linear-gradient(25deg, red, orange);
$purple-200: #dad0e4;
$purple-400: #7c6e90;
$purple-600: #5b4a74;
$purple-800: #5a2a92;
$purple-900: #421d6d;
$purple-1000: #311057;

//Font Variables:
$base-font-size: 14px;
$heading-font-family: "Urbanist";

//Spacing Variables :
$spacing-extra-small: 8px;
$spacing-small: 12px;
$spacing-medium: 14px;
$spacing-large: 16px;
$spacing-extra-large: 24px;
$spacing-ultra: 32px;

//Breakpoint Variabales :
$mobile: 768px;
$tablet: 1024px;
$desktop: 1440px;

//Border Variables:
$border-width: 1px;
$border-radius-small: 2px;
$border-radius-medium: 4px;
$border-radius-large: 8px;
