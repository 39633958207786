@import "../../styles/__variables.scss";

.container {
  h1 {
    font-size: 28px;
    font-weight: 700;
    color: $purple-1000;
    margin-bottom: 24px;
  }
  form {
    background-color: white;
    padding: 24px;
    width: 512px;
    border-radius: 4px;
    border-left: 4px solid orangered;

    .section {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      input {
        padding: 12px 16px;
        background-color: $purple-1000;
        color: white;
        width: 100%;
        outline: none;
        border-radius: 4px;
      }
    }

    .btnSection {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 24px;
      .primary {
        background: linear-gradient(20deg, red, orange);
        color: white;
        font-weight: 700;
      }

      .secondary {
        background: grey;
        color: white;
        font-weight: 700;
      }
    }
  }
}
