@import "../../styles/_variables";

.container {
.section1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .section2 {
    .name{
        color:$purple-900;
        font-size: $spacing-extra-large;
        font-weight: 600;
    }
    .description{
      color:$purple-400;
      font-size:$spacing-small;
    }
  }
}