.container {
  padding: 32px;
  width: 100%;
  .logo {
    img {
      width: 148px;
      height: 40px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 2rem;
    gap: 18px;

    li {
      background-color: green;
    }
  }
}
