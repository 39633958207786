@import "../../styles/_variables";

.container {
  height: 216px;
  width: 100%;
  background-color: $purple-900;
  border-radius: $border-radius-large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-extra-large;
  gap: $spacing-extra-large;

  .label {
    font-size: 12px;
    color: $primaryColor;
  }

  .textSection {
    display: flex;
    flex-direction: column;
    gap: $spacing-extra-small;
  }

  .title {
    font-size: 25px;
    font-weight: bold;
    font-family: $heading-font-family;
    color: $primaryColor;
  }

  button {
    height: 40px;
    font-size: 14px;
    color: $primaryColor;
    background: $accentColor;
  }
}
