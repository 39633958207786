@import "../../styles/_variables";

.container {
  width: auto;
  height: 200px;
  background-color: $primaryColor;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5em;
  padding: 10px;
  cursor:pointer;

  .heading {
    color: $purple-900;
    font-weight: 600;
    font-size: $spacing-large;
    padding-left: 10px;
  }

  .bodySection {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    margin-top: 10px;

    .section1,
    .section2,
    .section3 {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 350px;
      height: 120px;
      background-color: $primaryColor;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 15px;
      margin-top: 10px;
      transition: background 0.3s ease-in-out;
      position: relative;
      padding-top: 10px;

      &:hover:before,
      &:active:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(25deg, orange, red);
      }

      .description {
        color: $purple-400;
        font-size: $spacing-small;
      }
      .points {
        color: $purple-1000;
        font-size: $spacing-ultra;
      }
      .artistName {
        color: $purple-900;
        font-size: $spacing-medium;
        margin-top: 5px;
        font-weight: 500;
      }
      .jobs {
        color: $purple-600;
        font-weight: 600;
      }
    }

    .profile {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid black;
    }
  }
}
