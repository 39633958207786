.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .leftSection {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .rightSection {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
