.progress-bar {
  width: 100%;
  background-color: #ddd;
  border-radius: 4px;
  overflow: hidden;
  border-radius: 26px;
  height: 14px;

  .progress-fill {
    height: 100%;
    width: 0%;
    background-color: #76a9fa;
    transition: width 0.3s ease-in-out;
    text-align: right;
    padding-right: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-size: 12px;
    font-weight: 700;
    border-radius: 26px;
  }
}
