.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 24px;
  width: calc(100% - 48px);
  background-color: #f1f0f8;
  border-radius: 4px;
  height: 68px;
  width: calc(100% - 48px);
  .row {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  svg {
    background-color: white;
    color: rgb(49, 16, 87);
    width: 40px;
    height: 40px;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
