// ComplianceContainer.module.scss

@import "../../styles/_variables";

.container {
  p {
    color: $purple-400;
    font-size: $spacing-medium;
  }
  .headerSection {
    display: flex;
    align-items: center;
    gap: 20px;

    .image {
      width: 45px;
      height: 40px;
    }

    .heading {
      color: $purple-900;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .mainSection {
    width: 100%;
    max-width: 1400px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .section1,
    .section2 {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      padding: 20px;
      background-color: $primaryColor;
      border-radius: 8px;

      .subSection1,
      .subSection2,
      .subSection3 {
        flex: 1 1 auto;

        p {
          font-weight: bold;
          margin-bottom: 10px;
        }

        input,
        select {
          width: 100%;
          padding: 12px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
        }

        .customSelectWrapper {
          position: relative;
          width: 300px;
          
          .customSelectTrigger {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 12px;
            background: white;
            border: 1px solid #ccc;
            border-radius: 4px;
            span {
              margin-left: 10px;
              font-size: 16px;
              flex-grow: 1; /* Ensure the text uses available space */
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .customOptions {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: white;
            border: 1px solid #ccc;
            border-radius: 4px;
            z-index: 99;
            .customOption {
              display: flex;
              align-items: center;
              padding: 10px;
              cursor: pointer;
              &:hover {
                background: #f0f0f0;
              }
            }
          }
        }

        .roundedImage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .imageSection {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 4px;
          }

          .overlay {
            position: relative;
            width: 40px;
            height: 40px;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0.7;
              border-radius: 4px;
              background-color: black; // Add black background
              mix-blend-mode: multiply; // Ensure the image blends well with the background
            }

            .overlayText {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 14px;
              font-weight: bold;
              color: white; // Change text color to white
              opacity: 1; // Ensure text is fully opaque
              pointer-events: none; // Ensure clicks pass through the overlay
            }
          }
        }

        textarea {
          width: 80%;
          height: 120px;
          padding: 24px;
          border: 1px solid #ccc;
          border-radius: 4px;
          resize: vertical;
          font-size: 16px;
        }
      }
    }

    .section3 {
      flex: 1 1 100%;
      margin-top: 20px;

      p {
        font-weight: bold;
        margin-bottom: 10px;
      }

      textarea {
        width: 100%;
        height: 120px;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
        font-size: 16px;
      }
    }

    .section2 {
      input {
        width: 100%;
      }
    }

    .section4 {
      flex: 1 1 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;

      .startButton,
      .secondaryButton {
        padding: 8px 20px; // Increased padding for buttons
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.startButton {
          color: $primaryColor;
          background: $accentColor;
        }

        &.secondaryButton {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $purple-400;
          color: $purple-900;
          background-color: transparent;

          &:hover {
            background-color: $purple-400;
            color: $secondaryColor;
          }
        }
      }
    }
    .startButton,
    .secondaryButton {
      padding: 8px 20px; // Increased padding for buttons
      font-size: 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 35px;
      transition: background-color 0.3s ease;

      &.startButton {
        color: $primaryColor;
        background: $accentColor;
      }

      &.secondaryButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $purple-400;
        color: $purple-900;
        background-color: transparent;

        &:hover {
          background-color: $purple-400;
          color: $secondaryColor;
        }
      }
    }
  }
}
