.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .titleSection {
    font-size: 26px;
    font-weight: 700;
    font-family: "Urbanist";
  }

  .tabSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid rgba(91, 74, 116, 0.2);

    .tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 4px 12px;

      &:hover {
        cursor: pointer;
        background-color: rgba(91, 74, 116, 0.4);
        color: white;
      }
    }
  }

  .searchSection {
    width: 100%;
    background-color: white;
    border-radius: 24px;

    input {
      border-radius: 24px;
    }
  }

  .tableSection {
    width: 100%;
    overflow: scroll;
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          background-color: rgba(91, 74, 116, 0.4);
          border-radius: 24px;

          td {
            padding: 12px;
            font-weight: 600;
            font-family: "Inter";
            color: rgba(91, 74, 116, 2);
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          background-color: rgba(91, 74, 116, 0.1);
          margin-top: 1rem;

          td {
            padding: 12px 16px;

            img {
              width: 120px;
              height: 64px;
              object-fit: cover;
              border-radius: 12px;
            }
            .comment {
              min-width: 180px !important;
              overflow: scroll;
            }

            .approve {
              background-color: #4cbb17;
              color: #f1fee7;
            }

            .reject {
              color: #fef3f2;
              background-color: #db3527;
            }
          }
        }
      }
    }
  }
}
