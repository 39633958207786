@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 1440px; */
  margin: 0 auto;
}

* {
  margin: 0;
  padding: 0;
}
