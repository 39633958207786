.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  padding: 32px;

  .rowSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
  }

  .rightSection {
    background-color: #ececec77;
    border: 1px solid grey;
    border-radius: 12px;
    padding: 32px 64px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;

    .titleSection {
      width: 100%;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 36px;
        font-family: "Inter";
        font-weight: 700;
        color: #2a2354;
      }

      p {
        color: #2a2354;
      }

      .formSection {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        table {
          width: 100%;
          overflow-x: scroll;
        }

        thead {
          background-color: rgb(210, 210, 210);

          td {
            padding: 4px 8px;
            border: 1px solid grey;
            font-weight: 700;
            font-size: 14px;
          }
        }

        tbody {
          td {
            padding: 4px 8px;
            border: 1px solid grey;
            font-weight: 400;
            font-size: 14px;
          }

          button {
            padding: 4px 8px !important;
            height: 32px;
            width: 112px !important;
            background-image: linear-gradient(25deg, red, orange);
            color: white;
            font-size: 14px;
            font-weight: 700;
          }

          .btnSection {
            display: flex;
            flex-direction: row;
            gap: 12px;
            height: 100%;
            padding: 4px 8px;
          }
        }

        .form {
          label {
            color: orangered;
            font-size: 14px;
            font-weight: 700;
          }
          input,
          select {
            background-color: inherit;
            border: none;
            border-bottom: 1px solid grey;
            outline: none;
            color: #2a2354;
            font-weight: 500;
            font-size: 14px;
            padding: 4px 0;
          }
        }
      }
    }
  }

  .leftSection {
    background-color: #ececec77;
    border: 1px solid grey;
    width: 512px;
    border-radius: 12px;
    padding: 32px 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;

    .buttonSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;

      .primary {
        background-image: linear-gradient(25deg, red, orange);
        color: white;
        font-weight: 700;
      }
    }

    .titleSection {
      width: 100%;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 36px;
        font-family: "Inter";
        font-weight: 700;
        color: #2a2354;
      }

      p {
        color: #2a2354;
      }

      .formSection {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .form {
          label {
            color: orangered;
            font-size: 14px;
            font-weight: 700;
          }
          input,
          select {
            background-color: inherit;
            border: none;
            border-bottom: 1px solid grey;
            outline: none;
            color: #2a2354;
            font-weight: 500;
            font-size: 14px;
            padding: 4px 0;
          }
        }
      }
    }
  }
}
