.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 512px;

  .titleSection {
    padding: 12px 18px;
    font-weight: 700;
    font-size: 28px;
    color: rgb(91, 74, 116);
  }

  .contentSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .inputField {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        width: 240px;
      }

      select {
        width: 240px;
        padding: 12px 16px;
        background-color: white;
      }
    }
  }
  .buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      padding: 12px 18px;
      background-color: rgb(202, 196, 196);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .primary {
      background-image: linear-gradient(25deg, orange, red);
      color: white;
      font-weight: 700;
    }
  }
}
