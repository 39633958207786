@import "../../styles//_variables";

.container {
  display: flex;
  flex-direction: row;

  .stickyHeader {
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .rowSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bodySection {
    background-color: $secondaryColor;
    width: 100%;
    height: 98vh;
    overflow-y: scroll;
    border-radius: $spacing-extra-large 0 0 0;
    padding: $spacing-ultra;
    display: flex;
    flex-direction: column;
    gap: $spacing-large;

    .footer {
      display: flex;
      flex-direction: row-reverse;
      p {
        font-size: 12px;
        color: $purple-400;
      }
    }
  
  }
}
