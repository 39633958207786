.container {
  background-color: white;
  width: calc(100% - 48px);
  border-radius: 8px;
  padding: 24px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    margin-top: 2rem;
  }
}
