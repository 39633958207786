@import "../../styles/_variables";

.container {
  .headerSection {
    display: flex;
    align-items: center;
    gap: 20px;

    .image {
      width: 45px;
      height: 40px;
    }

    .heading {
      color: $purple-900;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .bodySection {
    width: auto;
    height: 500px;
    background-color: $primaryColor;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding:10px;
  }

  .tableSection {
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;
      text-align: center;
      margin-top: 10px;

      thead {
        background-color: $secondaryColor;

        tr {
          border-radius: $border-radius-small;

          td {
            padding: $spacing-large;
            font-size: 14px;
            font-weight: bold;
            color: $purple-1000;
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: $secondaryColor;
          }

          td {
            padding: $spacing-large;
            font-weight: semi-bold;
            color: $purple-400;
          }

          .strong {
            font-weight: bold;
          }

          button {
            display: flex;
            flex-direction: row;
            gap: $spacing-extra-small;
            align-items: center;
            justify-content: center;

            img {
              height: 12px;
            }

            p {
              font-size: 14px;
            }

            border: 1px solid $purple-400;
            height: 32px;
            width: 88px;

            &:hover {
              background-color: $purple-400;
              color: $secondaryColor;
            }
          }
        }
      }
    }
  }
}
