@import "../../styles/_variables";

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondaryColor; // Replace with your background color variable if you have one
  padding: 20px; // Add padding around the container

  .section1 {
    text-align: center;
    padding: 20px; // Add padding inside the section for better spacing

    .lockImage {
      width: 150px;
      height: auto;
      border-radius: 100%; // Makes the image circular
      display: block;
      margin: 0 auto; // Center the image horizontally
    }

    .message {
      margin-top: 20px;
      font-size: 1rem;
      color: $purple-400;
      margin-bottom: 20px; 
    }
    .accessMessage{
    
            margin-top: 20px;
            font-size: 2rem;
            color: $purple-900;
            margin-bottom: 10px; 
            font-weight: 700;
          
    }

    .startButton,
    .secondaryButton {
      padding: 8px 20px; 
      font-size: 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 20px;
      transition: background-color 0.3s ease;

      &.startButton {
        color: $primaryColor;
        background: $accentColor;
      }

      &.secondaryButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $purple-400;
        color: $purple-900;
        background-color: transparent;

        &:hover {
          background-color: $purple-400;
          color: $secondaryColor;
        }
      }
    }
  }
}
