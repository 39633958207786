@import "../../styles/_variables";

.container {
  background-color: $primaryColor;
  height: 296px;
  width: 49.2%;
  border-radius: $border-radius-large;
  padding: $spacing-extra-large;
  display: flex;
  flex-direction: column;
  gap: $spacing-extra-large;


  .titleSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
