.container {
  width: 100%;
  height: 640px;
  background-color: white;
  border: 1px solid rgb(210, 210, 210);
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 48px;
  border-radius: 24px;

  .form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input {
      outline: none;
      border-radius: 4px;
      border: 0.5px solid grey;
      height: 120px;
    }

    .label {
      padding: 12px;
      width: 280px;
      border: 0.5px solid grey;
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.3s;
      &:hover {
        background-color: rgb(229, 229, 229);
        font-weight: 500;
      }
    }
  }

  .buttonSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    gap: 12px;

    button {
      padding: 4px 8px;
      border: 4px solid transparent;
    }

    .primary {
      background-image: linear-gradient(25deg, orange, red);
      color: white;
      font-weight: 700;
      border: none;
    }

    .secondary {
      border: 1px solid grey;
      color: black;
      font-weight: 700;

      &:hover {
        background-color: grey;
        color: white;
      }
    }
  }
}
