.container {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  min-height: 180px;
  height: 100vh;
  overflow: scroll;

  .line {
    height: 1px;
    background-color: #b899dc66;
  }

  .formSection {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;

    h1 {
      font-weight: 600;
      font-size: 18px;
      color: #351b52f1;
      text-decoration: underline;
    }

    .inputSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .resultContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .success {
        background-color: rgba(64, 146, 71, 0.8);
        border-radius: 4px;
        color: white;
        font-size: 12px;
        font-weight: 600;
        width: 60%;
        padding: 8px 12px;
        text-align: center;
      }

      .error {
        background-color: rgba(204, 29, 29, 0.8);
        border-radius: 4px;
        color: white;
        font-size: 12px;
        font-weight: 600;
        width: 60%;
        padding: 4px 12px;
        text-align: center;
      }
    }

    input,
    select {
      outline: none;
      width: 240px;
    }

    button {
      background-image: linear-gradient(25deg, red, orange);
      color: white;
      font-weight: 700;

      &:hover {
        background-image: linear-gradient(25deg, orange, red);
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 8px 16px;
      text-align: center;
      border: 1px solid #b899dc66;
    }

    thead {
      color: #7c6e90;
      text-align: left;

      td {
        padding: 20px 16px;
        color: #e2d8ec;
        font-size: 12px;
        font-weight: 700;
        border: 0.1px solid grey;
        background-color: #442b61;
      }
    }

    tbody {
      color: #2e164a;
      text-align: left;

      td {
        padding: 20px 16px;
        color: #311057;
        font-weight: 500;
        font-size: 12px;

        button {
          padding: 4px 8px !important;
          max-height: 36px;
          min-width: 120px;
          font-size: 12px;
        }

        .secondary {
          border: 1px solid grey;
          font-weight: 400;

          &:hover {
            background-color: #dbd4e4;
            color: #311057;
          }
        }
        .primary {
          background-image: linear-gradient(25deg, red, orange);
          width: 132px;
          color: white;
          font-weight: 700;
        }
      }

      tr {
        border-bottom: 1px solid #b899dc66;
        margin-bottom: 1rem !important;

        &:hover {
          cursor: pointer;
          background-color: #b899dc29;
        }
      }
    }
  }
}
