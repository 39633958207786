.navbutton {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 232px;
  height: 48px;
  border: none;
  border-radius: 8px;
  padding: 0.5em 1em;
  background-color: white;
  outline: none;
  font-weight: 500;
  transition: 0.2s ease-in all;

  &:hover {
    cursor: pointer;
    background-color: #f1f0f8;
    color: #5b4a74;
  }

  .active {
    background-color: #f1f0f8;
    color: #5b4a74;
  }
}
