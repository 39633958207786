@import "../../styles//_variables";

.container{
    width:auto;
    background-color:$primaryColor;
    margin:10px;
    border-radius: 0.1em;
    height:70vh;
    overflow-y: scroll;
    padding:15px;
    line-height: 2.2em;

    .productType{
        font-size: 13px;
        color:$purple-400;



    }
    .product{
        color:$purple-900;
        font-weight: 700;
        font-size: 20px;
        margin:10px 0 10px 0;
    }
    .productDescription{

        color:$purple-600;
        font-weight: 600;
        
    }
    // .items{
    //     display:flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     flex-direction: row;

    // }
    .section1{
        display:flex;
        align-items: center;
        justify-content: space-between;
        
        // flex-direction: column;

         .items{
        display:flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap:5rem;

    }

    // .listName{
    //     color:
    // }
    .images{
      
        display:flex;
        align-items: center;
        justify-content: space-around;
        gap:15px;


        img{
            width:100px;
            height:100px;
            border-radius: 5%;
            
        }

        .overlayContainer {
            position: relative;
            width: 100px;
            height: 100px;
    
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 0.2em;
            }
    
            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: 1.2em;
              font-weight: normal;
              border-radius: 0.2em;
              
            }
        }
    }
   
  
    }
    .section3{

        display:flex;
        align-items: center;
        justify-content: space-between;
    }
}

