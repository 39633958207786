.button {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    opacity: 0.9;
  }
}
