.container {
  display: flex;
  flex-direction: row;

  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding: 24px;
    background-color: #f1f0f8;
    height: 100vh;
    overflow-y: scroll;
    border-radius: 24px 0 0 0;

    .firstHeader {
      display: grid;
      grid-template-columns: 29% 70%;
      gap: 1%;
    }

    .equalColumn {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      gap: 1.4%;
    }

    .equalColumnRow {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .firstHeader {
    display: grid;
    grid-template-columns: minmax(0, 1fr) !important;
    gap: 1%;
  }
  .equalColumn {
    grid-template-columns: minmax(0, 1fr) !important;
    gap: 1%;
  }
}
