.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100vh;

  .titleSection {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #442b61;

    h1 {
      font-size: 28px;
      font-weight: 700;
    }

    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .listSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    border: 1px solid rgba(128, 128, 128, 0.224);
    padding: 24px;
    border-radius: 8px;
    overflow-y: scroll;
    background-color: white;

    .listDisabled {
      @extend .list;
      background-color: rgb(206, 206, 206);

      h1,
      p {
        color: rgb(129, 129, 129) !important;
      }

      .iconSection {
        color: rgb(84, 84, 84) !important;
        background-color: rgb(160, 160, 160) !important;
      }

      border: none;

      &:hover {
        cursor: not-allowed !important;
        background-color: rgb(206, 206, 206) !important;
      }
    }

    .list {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;
      padding: 12px;
      border: 0.05px solid #d9d9d9;
      border-radius: 8px;
      height: 100px;

      &:hover {
        cursor: pointer;
        background-color: #b899dc29;
      }

      .iconSection {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background-color: #442b61;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
      }

      .textSection {
        color: #442b61;
        h1 {
          font-size: 22px;
          font-weight: 600;
        }

        p {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .container {
    .listSection {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
