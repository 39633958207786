@import "../../styles/_variables";

.navbarContainer {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: 32px;
  width: 296px;
  border-radius: 0px 24px 24px 0;
  height: 100vh;

  .logoSection {
    img {
      height: 40px;
      width: 148px;
    }
  }

  .menuSection {
    display: flex;
    flex-direction: column;
    gap: $spacing-large;
  }

  .footerSection {
    margin-top: 85%;

    .line {
      height: 1px;
      background-color: $purple-200;
    }
  }

  .shrinkSection {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    img {
      height: 14px;
      transition: all 0.3s ease-in-out;

      &:hover {
        height: 16px;
        cursor: pointer;
      }
    }
  }
}

//Shrinked Menu:
.shrinked {
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: $spacing-medium;
  width: 72px;
  height: 100vh;
  

  .logoSection {
    img {
      height: 40px;
      width: 72px;
    }
  }

  .menuSection {
    display: flex;
    flex-direction: column;
    gap: $spacing-large;
  }

  .footerSection {
    margin-top: 85%;

    .line {
      height: 1px;
      background-color: $purple-200;
    }
  }

  .shrinkSection {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    cursor: pointer;
    img {
      height: 12px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.hidden {
  display: none;
}
