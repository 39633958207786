.container {
  width: 512px;
  height: 562px;
  border-radius: 8px;
  background-color: white;
  color: rgb(91, 74, 116);
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow-y: scroll;

  .inputSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .inputSectionHalf {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}

p {
  font-size: 16px;
  font-weight: 600;
}

input {
  width: calc(100% - 32px);
  padding: 12px 16px;
  border: 1px solid #f1f0f8;
  outline: none;
  border-radius: 2px;
}

input[type="file"] {
  border: none;
  background-color: #f1f0f8;
  padding: 48px 24px;
  width: calc(100% - 48px);
  border-radius: 2px;
}

select {
  width: 100%;
  border: none;
  padding: 12px 16px;
  border-radius: 2px;
  background-color: #f1f0f8;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button {
  height: 40px;
  width: 160px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.primary {
  background-image: linear-gradient(25deg, orange, red);
  color: white;
  &:hover {
    background-image: linear-gradient(25deg, red, orange);
  }
}

.addTag {
  font-size: 12px;

  &:hover {
    cursor: pointer;
    color: grey;
  }
}
