@import "../../styles/_variables";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loginbox {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
    background-color: $purple-200;
    padding: 48px;
    margin-top: 15%;
    width: 600px;
    border-radius: 8px;
    border: 0.5px solid $purple-400;
  }

  input {
    width: 100%;
    outline: none;
    border-radius: 4px;
    border-bottom: 1px solid $purple-400;
  }

  label {
    font-weight: 700;
    font-size: 16px;
  }

  p {
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 700;
    color: rgb(147, 23, 23);
  }

  .buttonSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
      width: 50%;
      outline: none;
    }
  }

  .primary {
    background-image: linear-gradient(25deg, red, orange);
    color: white;
    font-weight: 700;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(25deg, orange, red);
    }
  }

  .secondary {
    padding: 24px;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    color: rgb(109, 109, 109);
    font-weight: 700;

    &:hover {
      cursor: pointer;
      background: grey;
      color: black;
    }
  }
}
