@import "../../styles/_variables";
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .searchSection {
    display: flex;
    flex-direction: row;
    gap: $spacing-extra-small;
    background-color: $primaryColor;
    height: 40px;
    align-items: center;
    border-radius: $border-radius-large;
    padding: $spacing-extra-small;

    img {
      width: 18px;
    }
    input {
      border: none;
      outline: none;

      height: 32px;
    }
  }

  .iconSection {
    display: flex;
    flex-direction: row;
    gap: $spacing-extra-small;

    img {
      background-color: white;
      border-radius: 60%;
      height: 40px;
      padding: 10px;
    }
    .profile {
      height: 40px;
      width: 40px;
      border: 2px solid rgb(122, 190, 122);
      object-fit: cover;
    }
  }
}
