@import "../../styles/_variables";


.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-large;
    padding: $spacing-small;
    background-color: $primaryColor;
    border-radius: $border-radius-large;
    transition: all .2s;


    img{
        width: 16px;
        height: 16px;
    }

    p{
        font-size: $base-font-size;
        font-weight: 400px;
        font-family: $heading-font-family;
        color: $purple-400;
    }


    &:hover{
        cursor: pointer;
        background-color: $secondaryColor;
        

        p{
            font-weight: 700;
            color: $purple-900;
        }

    }
    

}

.active{

}
