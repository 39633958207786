@import "../../styles/_variables";

.container{
    display:flex;
    align-items: center;
    justify-content: space-between;

    .section1{
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:30px;
      .active{
        background-color: $purple-800;
        border:1px solid purple;
        height: 30px;
        width:30px;
      }
    }
    
    .section2{
        background-color: $primaryColor;
        height:400px;
        width:650px;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        .title{
            color:$purple-900;
            font-size:$spacing-large;
            font-weight: 600;
            padding:10px;
        }
        .description{
            color:$purple-400;
            font-size: $spacing-medium;
            font-weight: 500;
            padding:10px;
        }
        .button{
            color:$purple-800;
            background-color: $secondaryColor;
            
        }

    }

}
.footer{
    display:flex;
    align-items: center;
    justify-content: space-around;
    height:110px;
    margin-top: 30px;
    background-color: white;
    .button2{
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:10px;

    }
    .button{
        color:$purple-800;
        background:transparent; 
        border:1px solid $purple-200;
        border-radius: 8px;
        
    }
    .startButton {
        height: 40px;
        font-size: 14px;
        color: $primaryColor;
        background: $accentColor;
        border: none;
        padding: 0 0.2rem;
        cursor: pointer;
        border-radius: 8px;

      }
   
}