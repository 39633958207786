@import "../../styles//_variables";

.container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding-top:10px;

    .section2,.section3{
        display:flex;
    align-items: center;
    justify-content: space-between;
    gap:6px;
    }
    .title{
        color:$purple-900;
    }
    .time{
        color:$purple-800;
        font-weight: 400;
    }
    .heading{
        color:$purple-900;
        font-size: $spacing-extra-large;
        font-weight: 600;
    }
    .description{
        color:$purple-400;
        font-size: $spacing-medium;
        font-weight: 500;
    }
}
