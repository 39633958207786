@import "../../styles//_variables";


.container{
    display:flex;
    align-items:center;

    gap:2rem;
    justify-content: space-between;

    .imageSection {
        background-color:$primaryColor;
        width:40px;
        height:40px;
        border-radius: 100%;
       
        

    }

    
// .imageSection img{
//     transform: rotate(180deg);
//     margin:10px 0px 0 12px;

//     width:30px;
//     height:20px;@import "../../styles/_variables";

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .section {
    display: flex;
    flex-direction: row;
    gap: 1em;
  
    .imageSection {
      background-color: $primaryColor;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  
    //   img {
    //     transform: rotate(180deg);
    //     width: 20px;
    //     height: 20px;
    //   }
    }
  
    .section1 {
      .jobDetail {
        color: $purple-900;
        font-size: larger;
        font-weight: 600;
      }
  
      .section2 {
        display: flex;
        align-items: center;
        gap: 0.5em;
  
        .description {
          font-size: 12px;
          color: $purple-400;
        }
  
        .active {
          width: 8px;
          height: 8px;
          background-color: $purple-400;
          border-radius: 100%;
        }
      }
    }
  }
  
  .actionSection {
    display: flex;
    align-items: center;
    gap: 1rem;
  
    .startButton {
      height: 40px;
      font-size: 14px;
      color: $primaryColor;
      background: $accentColor;
      border: none;
      padding: 0 0.2rem;
      cursor: pointer;
    }
  
    .kebabIcon {
      background-color: $primaryColor;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      cursor: pointer;
      border-radius: 0.5em;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
  

// }
.jobDetail{
   color:$purple-900;
   font-size: larger;
   font-weight: 600;
}
.section{
    display: flex;
    flex-direction: row;
    gap:1em;
}
.section1{
  
    
    .section2{
        display:flex;
        align-items: center;
        gap:0.5em;
    
        .description{
            font-size:12px;
            color:$purple-400;
        
        }
        .active{
           width:8px;
           height:8px;
           background-color:$purple-400;
           border-radius: 100%;
        }
    
     
    }
   
    
}

button {
    height: 40px;
    font-size: 14px;
    color: $primaryColor;
    background: $accentColor;
    border-radius: 0.5em;
  }

  .kebabIcon{
    background-color: $primaryColor;
    width:20px;
    height:20px;
    display: flex;
    align-items: center;
  }



}

.footer {
    display: flex;
    flex-direction: row-reverse;
    p {
      font-size: 12px;
      color: $purple-400;
    }
  }




