.container {
  display: flex;
  width: 288px;
  height: 40px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  border-radius: 8px;
  padding: 0 12px;
  gap: 12px;

  input {
    outline: none;
    border: none;
    padding: 4px;
    color: #5b4a74;
    width: 100%;
  }

  .icon {
    color: #5b4a74;
  }
}
