.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: contain;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }
}
