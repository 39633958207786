@import "../../styles/_variables";

.container {
//   max-width: 800px;
  width:auto;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .mainSection{
    display:flex;
    align-items: center;
    justify-content: space-between;
    // gap:20px !important;
    input{
        width:400px !important;
        height:35px;
        margin:20px 0 20px 0;
        // margin-bottom: 10px;
        // margin-right: 50px;
        
    }
  }

  .startButton {
    height: 40px;
    font-size: 14px;
    color: $primaryColor;
    background: $accentColor;
    border: none;
    padding: 0 0.2rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s ease;

    // &:hover {
    //   background: darken($accentColor, 10%);
    // }
  }

  .secondaryButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid $purple-400;
    height: 32px;
    width: 150px;
    cursor: pointer;
    background-color: transparent;
    color:$purple-900;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: $purple-400;
      color: $secondaryColor;
    }
  }

  .section1,
  .section2,
  .section3,
  .section4,
  .section5,
  .section6 {
    margin-bottom: 20px;
  }

  .section1 {
    display: flex;
    align-items: center;
    gap: 10px;

    input {
      flex: 1;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      
    }
  }

  .section2 {
    p {
      margin-bottom: 10px;
      font-weight: bold;
      color: $purple-900;
    }

    .section {
      display: flex;
    
      align-items: center;

      input[type="radio"] {
        // margin-right: 10px;
        cursor: pointer;
        width:50px;

      }

      label {
        cursor: pointer;
      }
    }
  }

  .section3 {
    display: flex;
    gap: 20px;
  }

  .section4,
  .section5 {
    p {
      margin-bottom: 10px;
      font-weight: bold;
      color: $purple-900;
    }

    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .selectSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .section6 {
    textarea {
      width: 100%;
      height: 100px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      resize: none;
    }

    .secondaryButton {
      margin-top: 10px;
      width: 100%;
    }
  }
}
