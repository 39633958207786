.popup {
  width: 512px;
  background-color: rgba(164, 228, 164, 0.177);
  display: flex;
  margin: 0 auto;
  z-index: 999;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid green;
  display: none;
  margin-top: 4rem;

  p {
    font-weight: 500;
    font-size: 16px;
    color: green;
    text-indent: 4%;
  }
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  .faded {
    opacity: 0.2;
  }

  .miniContainer {
    width: 1200px;
    margin: 0 auto;
    padding: 48px;
  }

  .title {
    background-color: #777487;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 24px;
    color: rgb(225, 225, 235);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      padding: 0 12px;
      background-color: #b7b5c6;
      border-radius: 4px;
      margin-left: 2rem;
      font-size: 14px;
      color: rgb(106, 104, 117);
    }
  }

  input {
    width: 100%;
    height: 320px;
  }

  .message {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #743535;
    border-radius: 5px;
    background-color: #ffd1d1;
    color: #333;
  }
}
