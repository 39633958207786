@import "../../styles/_variables";

.container {


  .heading {
    color: $purple-900;
    font-weight: 700;
    font-size: $spacing-large;
    padding-left: 10px;
  }

  .bodySection {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    margin-top: 10px;
    
  cursor:pointer;

    .section1,
    .section2,
    .section3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 360px;
      height: 150px;
      background-color: $primaryColor;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      padding: 15px;
      margin-top: 15px;
      
      transition: background 0.3s ease-in-out;
      position: relative;
      padding-top: 10px;
      border-radius: 8px;

    //   &:hover:before,
    //   &:active:before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 2px;
    //     background: linear-gradient(25deg, orange, red);
    //   }

      .description {
        color: $purple-400;
        font-size: $spacing-small;
      }
      .points {
        color: $purple-1000;
        font-size: $spacing-ultra;
      }
      .artistName {
        color: $purple-900;
        font-size: $spacing-large;
        margin-top: 10px;
        font-weight: 600;
      }
      .jobs {
        color: $purple-600;
        font-weight: 600;
        margin-top: 20px;
      }
    }

    .profile {
      width: 35px;
      height: 35px;
     margin-top: 40px;
    }
    .logo{
        width: 35px;
        height: 35px;
        object-fit: contain;
    }
  }
  .childContainer{
    width:auto;
    height:250px;
    background-color: $purple-900;
    margin:15px;
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: space-around;

    .startButton {
        height: 40px;
        font-size: 14px;
        color: $primaryColor;
        background: $accentColor;
        border: none;
        padding: 0 0.2rem;
        cursor: pointer;
      }
      .slackLogo{
        height:230px;
        width:300px;
      }
      .content1{
        color:$primaryColor;
        font-size: $spacing-large;

      }
      .content2{
        color:$primaryColor;
        font-size: $spacing-small;
      }

  }
}
