.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modalContent {
    background-color: #fff; /* White background for modal */
    width: calc(40% - 24px);
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    display: flex;
    flex-direction: column;
    gap: 24px 48px;

    .titleSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h1 {
        color: #442b61;
        font-weight: 700;
        font-size: 24px;
      }

      .icon {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .contentSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      .content {
        padding: 12px;
        width: 100%;
        border: 0.5px solid grey;
        cursor: pointer;
        border-radius: 4px;
        transition: all 0.3s;
      }

      input {
        background-color: none;
        border: none;
        outline: none;
      }
    }

    .footerSection {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 12px;

      button {
        height: 36px;
        width: 120px;
      }
      .primary {
        background: linear-gradient(25deg, red, orange);
        color: white;
        font-weight: 700;
      }

      .secondary {
        border: 1px solid rgb(125, 106, 125);
        font-weight: 500;

        &:hover {
          background: rgb(125, 106, 125);
          color: white;
        }
      }
    }
  }
}
