
@import '../../../styles/__variables.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: $secondaryColor;

  .rightSection {
    display: flex;
    flex-direction: row;
    gap: 12px;
    .icon {
      font-size: 20px;
      padding: 12px;
      border-radius: 60%;
      background-color: white;

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }
}
